.main-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 80%;
  color: white;
  font-size: 1.5rem;
  text-align: center;
  align-items: center;
}

.main-container img {
  height: auto;
  width: 70%;
}

.main-container li {
  margin-bottom: 10px; /* or whatever amount of space you prefer */
}

@media (max-width: 768px) {
  .main-container img {
    width: 100%;
  }

  .main-container h2 {
    font-size: 2rem;
  }
}

.main-container ul,
ol {
  text-align: left; /* aligns the text to the left */
  padding-left: 20px; /* adds some padding to ensure the list isn't right against the edge of its container */
  align-self: flex-start; /* overrides the center alignment from the flex container */
}
