.main-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 80%;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    background: #141721;
}
