.carousel-container {
  display: flex;
  max-width: 100%;
  max-height: 80%;
  margin: auto;
}

.carousel-image {
  max-width: 90%;
  max-height: 80%;
  border: 10px solid white;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .carousel-image {
    width: 100%;
    height: 100%;
  }
}
