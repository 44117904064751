.box {
  padding: 80px 60px;
  background: #11141b;
  bottom: 0;
  width: 100%;
}

@media (max-width: 1000px) {
  .box {
    padding: 70px 30px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
}

.column {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 5vh;
}

.row {
  display: flex;
  width: 90vw;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
}

@media (max-width: 1000px) {
  .row {
    display: grid;
    grid-column: auto;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.footer-link {
  color: #fff;
  margin-bottom: 16px;
  font-size: 14px;
  text-decoration: none;
}

.footer-link:hover {
  color: hsl(210, 90%, 55%);
  transition: 200ms ease-in;
}

.heading {
  font-size: 18px;
  color: #fff;
  margin-bottom: 4vh;
  font-weight: bold;
}

.container .copyright {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: white;
}

.container .cftc-rule {
  color: white;
  text-align: left;
  margin-top: 20px;
  font-size: 14px;
}
