.strategy-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin-top: -5rem;
}

.hero-title-and-image-container {
  position: relative;
  text-align: center; /* Center the HeroTitle text */
  margin-top: -4rem; /* Adjust as needed */
  padding: 2rem;
}

.strategy-image-wrapper {
  cursor: pointer;
  position: absolute; /* Position the image absolutely to allow overlap */
  right: 0; /* Align the image to the right */
  top: 50%; /* Adjust top position to vertically align as needed */
  transform: translateY(-50%); /* Center the image vertically */
  max-width: 20%; /* Adjust the image width as needed */
}
.strategy-image-wrapper img {
  cursor: pointer;
}

/* Adjust for mobile */
@media (max-width: 768px) {
  .strategy-image-wrapper {
    position: static; /* Resets the positioning to flow naturally in the document */
    transform: none;
    top: auto;
    right: auto;
    max-width: 80%; /* Adjust the image size for mobile */
    margin: 20px auto 0; /* Adds some space above the image and centers it */
  }

  /* Optional: Adjust the container's padding on mobile for better spacing */
  .hero-title-and-image-container {
    padding: 1rem;
  }
}

/* Ensure HeroTitle does not extend full width if it causes layout issues */
.hero-title {
  display: inline-block; /* Allows for text centering within a relatively positioned parent */
  max-width: 70%; /* Adjust as needed to prevent overlap, or remove if overlap is desired */
}

.strategy-details-content {
  display: flex;
  align-items: center;
}

.strategy-table {
  display: flex;
  max-width: 93%;
  justify-content: center;
  margin-top: 1rem;
}

.returns-table-title {
  margin-top: 3rem;
  color: white;
}

@media (max-width: 768px) {
  .returns-table-title {
    margin-left: 12px;
  }
}

.strategy-table-footer {
  color: white;
  margin-left: 5%;
  margin-right: 5%;
}

.strategy-image img {
  width: 100%;
  height: 100%;
  margin: auto;
}

@media (max-width: 768px) {
  .strategy-table {
    width: 100%;
  }
}

.strategy-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.historical-title {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-left: 6rem;
  margin-top: 4rem;
  color: white;
  font-weight: 900;
  font-size: 2.5rem;
}

@media (max-width: 768px) {
  .historical-title {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
  }
}

.historical-container {
  display: flex;
  justify-content: space-between;
  margin-left: 6rem;
  margin-right: 4rem;
  margin-top: 4rem;
  height: 60%;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .historical-container {
    width: 70%;
    margin-left: 1rem;
  }
}

@media (max-width: 768px) {
  .historical-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.historical-block {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem;
  background-color: #11141b;
  margin-right: 2rem;
}

.historical-block h1 {
  color: white;
  display: flex;
  justify-content: center;
  font-weight: 900;
  font-size: 2rem;
  margin-top: 5px;
}

.historical-block img {
  width: 100%;
  height: 100%;
  border: 2px solid white;
}

@media (max-width: 768px) {
  .historical-block {
    width: 100%;
    height: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

.strategy-info h1 {
  color: #007bff;
  font-weight: 900;
  margin-bottom: 1rem;
  font-size: 4rem;
}

.historical-performance {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.strategy-section {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-left: 6rem;
  margin-right: 6rem;
}

@media (max-width: 768px) {
  .strategy-section {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: 8%;
    margin-right: 30%;
  }
}

.strategy-block {
  flex: 1 1 30%;
  padding: 2rem;
  background-color: #11141b;
  position: relative;
  border: 2px solid #007bff;
}

@media (max-width: 768px) {
  .strategy-block {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: flex-start;
  }
}

.strategy-block h1 {
  color: white;
  font-size: 24px;
  font-weight: 900;
  position: absolute;
  top: 10px;
}

.strategy-block p {
  color: white;
  margin-top: 2rem;
}

.highlights-section {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-left: 6rem;
  margin-right: 6rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .highlights-section {
    width: 80%;
    margin-left: 2rem;
  }
}

@media (max-width: 768px) {
  .highlights-section {
    display: flex;
    flex-direction: column;
  }
}

.highlights-block {
  flex: 1 1 30%;
  color: white;
  padding: 1rem;
  background-color: #11141b;
  position: relative;
  /*border: 3px solid hsl(15, 90%, 55%);*/
}

.highlights-block h1 {
  font-size: 2rem;
}
.modal-content {
  max-width: 60%;
  margin: auto;
}

.disclaimer-section {
  color: white;
  margin-left: 6rem;
  margin-right: 6rem;
}

@media (max-width: 768px) {
  .disclaimer-section {
    margin-left: 2rem;
  }
}

.disclaimer-section h1 {
  font-weight: 900;
  font-size: 2.5rem;
}

.responsive-table-container {
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  font-size: 16px;
  color: white;
}

table th,
table td {
  padding: 10px 15px;
  border: 1px solid #007bff;
  font-weight: 600;
}

table th {
  background-color: #258cf4;
}

table tr:nth-child(even) {
  background-color: #141721;
}

@media screen and (max-width: 768px) {
  /* Adjust breakpoint as needed */
  table {
    width: 100%;
    display: block;
    font-size: 12px;
  }

  table thead {
    display: block;
    float: left;
  }

  table tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }

  table tbody tr {
    display: inline-block;
    vertical-align: top;
  }

  table th,
  table td {
    display: block;
    box-sizing: border-box;
    width: 150px;
  }
}
