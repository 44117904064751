
.logo-container {
    display: flex;
    justify-content: center;
}

.logo-image {
max-width: 60%;
max-height: 60%;
margin-bottom: 2rem;
}

.nt-image-container {
    display: flex;
    justify-content: center;
}

.kinetick-image {
    display: flex;
    justify-content: center;
}

@media(max-width: 768px){
    .kinetick-image {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
}

@media(max-width: 768px){
   .logo-container {
       max-width: 60%;
       max-height: 60%;
       margin: auto;
   }

   .logo-image {
       max-width: 90%;
       max-height: 90%;
       margin-bottom: 2rem;
   }
}

.nt-image {
    display: flex;
    max-width: 20%;
    max-height: 20%;
    margin-top: 10%;
    margin: auto;
}

@media(max-width: 768px){
    .nt-image {
        max-width: 60%;
        max-height: 60%;
    }
}

.nt-content {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 80%;
    color: white;
}

.nt-content a {
    color: hsl(15, 90%, 55%);
}

.nt-heading {
    color: white;
    margin-top: 2rem;
    font-size: 1.5rem;
}
  