.blog-list-container {
  display: flex;
  flex-wrap: wrap;
  /*justify-content: space-between;*/
  justify-content: space-evenly;
  color: white;
  margin-left: 10%;
  margin-right: 10%;
  gap: 40px; /* Add this line to control spacing between cards */
}

.blog-card {
  max-width: 400px;
  min-height: 300px;
  margin-bottom: 40px;
  border-radius: 5px;
  overflow: hidden;
  opacity: .80;
  box-shadow: 10px 5px 20px black;
  backgroundColor: "#141721";
  transition: transform 0.2s ease-in-out;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-image {
  padding: 8px;
  border-radius: 16px;
  width: 500px; /* Set the desired image width */
  height: 240px; /* Set the desired image height */
  object-fit: cover; /* Maintain aspect ratio and cover container */
}

.blog-details {
  padding: 20px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .blog-list-container {
    /*flex-direction: column;*/
    /*justify-content: center;*/

  }

  .blog-card {
    width: 100%;
  }

  .blog-image {
    width: 100%; /* Full width for mobile */
    height: auto; /* Auto height to maintain aspect ratio */
  }
}
