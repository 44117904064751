.custom-card {
    position: relative; /* Add position relative for stacking context */
  }
  
  .custom-card::before {
    content: '';
    position: absolute;
    top: -10px; /* Adjust the top value to control the positioning of the shadow */
    left: -10px; /* Adjust the left value to control the positioning of the shadow */
    width: calc(100% + 20px); /* Adjust the width value to control the size of the shadow */
    height: calc(100% + 20px); /* Adjust the height value to control the size of the shadow */
    background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0)
    ); /* Add a linear gradient for lighting effect */
    filter: blur(12px); /* Add blur effect to the shadow */
    z-index: -1; /* Place the shadow layer below the card */
  }
  