.card {
  position: relative;
  justify-content: center;
  justify-items: center;
  width: 420px;
  height: 400px;
  padding: 1vw;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 1px 10px 10px black;
  background: hsl(210, 90%, 55%);
}

@media (max-width: 768px) {
  .card {
    display: inline-block;
    padding: 10vh;
    margin-bottom: 5vh;
    /*font-size: 2.5rem!* 30px *!;*/
    /*line-height: 2rem!* 36px *!;*/
  }
}

.content {
  display: flex;
  font-family: "Montserrat";
  font-weight: 900;
  margin-top: 10px;
  color: #ffffff;
  text-align: left;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .heading {
  color: white;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 22px;
  line-height: 1.2;
  z-index: 1;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .para {
  z-index: 1;
  text-align: left;
  color: slategray;
  font-size: 16px;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.card::before {
  content: "";
  position: absolute;
  border-radius: inherit;
  --s: 60px;
  --c1: #180a22;
  --c2: #4ea4a4;
}

.card:hover::before {
  animation-play-state: running;
  z-index: -1;
  width: 50%;
}

.card:hover .content .heading {
  color: white;
}
.card:hover .content .para {
  color: white;
}

.card:hover {
  box-shadow: 0rem 6px 13px rgba(91, 66, 243, 0.1),
    0rem 24px 24px rgba(91, 66, 243, 0.09),
    0rem 55px 33px rgba(91, 66, 243, 0.05),
    0rem 97px 39px rgba(91, 66, 243, 0.01), 0rem 152px 43px rgba(91, 66, 243, 0);
  scale: 1.05;
  color: #000000;
}

@keyframes moving {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
