.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 80vh;
  padding: 80px 20px; 
  color: white;
  position: relative;
  background: url("../../images/bookDemo.svg") no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}

.hero-section-content {
  position: relative;
  max-width: 800px;
  border-radius: 10px;
}

.hero-section h1 {
  font-size: 3.4rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
}
.hero-section-highlight {
  background: linear-gradient(90deg, #e45d41 0%, #9d518d 51%, #6b48c4 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  display: inline-block;
}

.hero-section-highlight:hover {
  filter: brightness(1.2);
}

.hero-section p {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.9);
}

.hero-section-button {
  background: linear-gradient(90deg, #4478d7 0%, #166dc1 100%);
  color: white;
  padding: 14px 28px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: 0px 4px 10px rgba(55, 114, 255, 0.3);
}

.hero-section-button:hover {
  background: linear-gradient(90deg, #5a80bd 0%, #003962 100%);
}

.portfolio-analyzer-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #D8D8D8;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  margin-bottom: 10px;
  transition: opacity 0.3s ease-in-out;
}

.portfolio-analyzer-link:hover {
  opacity: 0.8;
}

.arrow-icon {
  width: 16px;
  height: 16px;
  margin-left: 6px;
}


@media (max-width: 1024px) {
  .hero-section {
    min-height: 70vh;
    padding: 60px 15px;
    background-size: cover;
    background-attachment: scroll; 
  }

  .hero-section-content {
    max-width: 90%;
    padding: 15px;
  }

  .hero-section h1 {
    font-size: 2.5rem;
    line-height: 1.2;
  }

  .hero-section p {
    font-size: 1.1rem;
  }

  .hero-section-button {
    font-size: 1rem;
    padding: 12px 24px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    min-height: 60vh;
    padding: 50px 15px;
    background-size: cover;
  }

  .hero-section-content {
    max-width: 95%;
  }

  .hero-section h1 {
    font-size: 2rem;
    line-height: 1.2;
  }

  .hero-section p {
    font-size: 1rem;
  }

  .hero-section-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .portfolio-analyzer-link {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .hero-section {
    min-height: 50vh;
    padding: 40px 10px;
  }

  .hero-section-content {
    max-width: 100%;
  }

  .hero-section h1 {
    font-size: 1.8rem;
    line-height: 1.2;
  }

  .hero-section p {
    font-size: 0.95rem;
  }

  .hero-section-button {
    font-size: 0.9rem;
    padding: 10px 18px;
  }

  .arrow-icon {
    width: 12px;
    height: 12px;
  }
}
