.algos-btn {
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: radial-gradient(
      84.92% 150% at 50% 138.75%,
      rgba(255, 255, 255, 0.44) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 255, 255, 0.01);
}

.portfolio-returns {
  background-image: url("../images/trade_performance/zta_quant_portfolio_returns_table.png");
  background-size: contain; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the background image */
  background-clip: padding-box;
}

.bg-hero {
  background-image: url("../images/portfolio_analyzer/quant_portfolio_summary.png");
  background-size: contain; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the background image */
  background-clip: padding-box;
}

.bg-hero-perf {
  background-image: url("../images/portfolio_analyzer/quant_1_portfolio_correlation.png");

  /*background-image: url("../images/portfolio_analyzer/quant_1_portfolio_correlation.png");*/
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the background image */
  background-clip: padding-box;
}

@media (max-width: 768px) {
  .bg-hero-perf {
    background-size: contain;
  }
}
