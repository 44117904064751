.hero {
  display: grid;
  place-items: center;
  padding: 20px;
  grid-template-columns: 1fr;
  margin-bottom: 10px;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100%;
}

.hero-h1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.animated-word {
  --bg-size: 400%;
  --color-one: hsl(15, 90%, 55%);
  --color-two: hsl(235, 90%, 55%);
  --color-three: hsl(210, 90%, 55%);
  font-family: "Montserrat";
  font-weight: 700;
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-three),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text; /* Ensure compatibility with Safari */
  animation: gradientAnimation 5s linear infinite;
  margin-bottom: 10px;
  position: relative;
  z-index: 30; /* Ensure text is above other elements */
}

.hero h1 {
  --bg-size: 400%;
  --color-one: hsl(15, 90%, 55%);
  --color-two: hsl(235, 90%, 55%);
  --color-three: hsl(210, 90%, 55%);
  font-family: "Montserrat";
  font-weight: 900;
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-three),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text; /* Ensure compatibility with Safari */
  animation: gradientAnimation 5s linear infinite;
  margin-bottom: 10px;
  position: relative;
  z-index: 30; /* Ensure text is above other elements */
}

.safe-area-container {
  padding-top: env(safe-area-inset-top, 20px);
  padding-bottom: env(safe-area-inset-bottom, 20px);
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: clamp(1.8rem, 6vw, 2.5rem);
    text-align: center;
    line-height: 1.2;
    max-width: 100%;
    overflow: visible; /* Ensure text isn't clipped */
    white-space: nowrap; /* Prevent text from wrapping */
    padding: 10px; /* Add padding for better spacing */
    border: 1px solid red; /* Debugging: Check parent container bounds */
  }

  .animated-word {
    font-size: clamp(2rem, 5vw, 3rem);
    text-align: center;
    margin-top: 10px;
    max-width: 100%;
  }

  .hero-h1-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero h2 {
  font-size: 2em;
  font-weight: normal;
  color: white;
  font-family: "Montserrat";
  margin-right: 10%;
  margin-left: 10%;
}
