/* Popup Backdrop */
.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

/* Popup Modal */
.popup {
  width: 30%;
  max-width: 500px;
  background-color: #282c34;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Title styling */
.popup-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

/* Image container styling */
.image-container {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.strategy-image {
  max-width: 100%;
  height: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .popup {
    width: 90%;
    padding: 15px;
  }
  .input-field,
  .submit-button {
    padding: 12px;
  }
  .close-button {
    font-size: 20px;
  }
  .popup-title {
    font-size: 18px;
  }
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

/* Input Field */
.input-field {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  color: #282c34;
  box-sizing: border-box;
}

/* Button Style */
.submit-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Centered Text */
.centered-text {
  text-align: center;
  margin-top: 20px;
}

/* Checkbox Container */
.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
}
