.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-container {
    margin-top: 2rem;
    width: 100%;
    max-width: 40%;
    background-color: #11141b;
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 2rem;
  }

  @media(max-width: 768px){
      .form-container {
          max-width: 85%;
      }
  }
  
  .form-title {
    color: white;
    font-size: 24px;
    margin-bottom: 1rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    color: white;
    display: block;
    font-size: 18px;
    margin-bottom: 0.5rem;
  }
  
  input[type='text'],
  input[type='email'],
  textarea {
    width: 100%;
    padding: 0.5rem;
    font-size: 16px;
    border-radius: 4px;
    border: none;
  }
  
  textarea {
    height: 120px;
  }
  
  button {
    background-color: hsl(210, 90%, 55%);
    color: white;
    border: none;
    padding: 0.75rem 1rem;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }

  .success-message {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; /* Added to vertically center the content */
  }
  
  .success-message h1 {
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .success-message {
      flex-direction: column;
    }
  }
  