.post-container {
  color: white;
  margin-left: 10%;
  margin-right: 10%;
  padding: 5%;
  opacity: 0.8;
  border-radius: 20px;
  background: #11141b;
}

@media (max-width: 768px) {
  .post-container {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.post-container .blogImage {
  display: block; /* Ensures the image is a block element */
  margin: 0 auto; /* Center the image horizontally */
  max-width: 100%; /* Ensure the image doesn't exceed its container's width */
}

/* Author Section Styles */
.author-section {
  display: flex;
  color: white;
}

.author-avatar {
  width: 80px; /* Adjust the width as needed */
  height: 80px; /* Adjust the height as needed */
  border-radius: 50%; /* Makes the avatar circular */
  margin-right: 20px; /* Spacing between avatar and author details */
  object-fit: cover;
}

.author-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.author-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.publish-date,
.read-time {
  font-size: 14px;
  color: #888; /* Adjust the color as needed */
  margin: 0;
}
