.image-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 10px;
  z-index: 0;
  position: relative; /* Add position relative for stacking context */
  background-color: transparent;
}

.image-section-two::before {
  content: '';
  position: absolute;
  top: -10px; /* Adjust the top value to control the positioning of the shadow */
  left: -10px; /* Adjust the left value to control the positioning of the shadow */
  width: calc(100% + 20px); /* Adjust the width value to control the size of the shadow */
  height: calc(100% + 20px); /* Adjust the height value to control the size of the shadow */
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  ); /* Add a linear gradient for lighting effect */
  filter: blur(12px); /* Add blur effect to the shadow */
  z-index: -1; /* Place the shadow layer below the image */
}

.image-section img {
  max-width: 60%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 5%;
}

@media(max-width: 768px){
  .image-section img {
    max-width: 70%;
  }
}

