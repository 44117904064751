/* Add these at the bottom of MainHero.css (or in an appropriate section) */

.hero-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
}

.hero-btn {
  padding: 12px 24px;
  min-width: 150px;
  min-height: 50px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(90deg, #4478d7, #166dc1);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.2;
  white-space: normal;
}

.hero-btn:hover {
  background: linear-gradient(90deg, #3564b7, #134a91);
  transform: scale(1.03);
}

.hero-btn p {
  margin: 0;
  padding: 0 8px;
  white-space: normal;
}

.glitch,
.glitch::after {
  border: none;
  border-radius: 5px;
  background-color: transparent;
  position: relative;
}

.background-img {
  /*background-image: url("../images/tablet_mockup1.png"),*/
  background-image: linear-gradient(
      to bottom,
      #141721,
      rgb(0, 0, 0, 0.5),
      #141721
    ),
    linear-gradient(to top, #141721, rgb(0, 0, 0, 0.1), #141721);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: overlay;
  z-index: 20;
  align-items: center;
  justify-content: center;
  color: white; /* Adjust text color for visibility */
  text-align: center;
  position: relative;
}

.background-img::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent; /* Dark overlay with less opacity */
  z-index: 1; /* Ensure the overlay is on top of the background image */
}

.main-container {
  width: 100%;
  height: 100%;
  background-color: #111111;
  background-image: linear-gradient(
    32deg,
    rgba(8, 8, 8, 0.74) 30px,
    transparent
  );
  background-size: 60px 60px;
  background-position: -5px -5px;
}

.glitch::after {
  --move1: inset(50% 50% 50% 50%);
  --move2: inset(31% 0 40% 0);
  --move3: inset(39% 0 15% 0);
  --move4: inset(45% 0 40% 0);
  --move5: inset(45% 0 6% 0);
  --move6: inset(14% 0 61% 0);
  clip-path: var(--move1);
  content: "ZTA";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.glitch:hover::after {
  animation: glitch_4011 1s;
  animation-timing-function: steps(2, end);
  text-shadow: -3px -3px 0px #00f968, 3px 3px 0px #04dfe2;
  background-color: transparent;
  border: 3px solid #ccffff;
}

.glitch:hover {
  text-shadow: 0px 0px 0px #04dfe2, 1px 1px 0px #04dfe2;
}

.glitch:hover {
  background-color: transparent;
}

@keyframes glitch_4011 {
  0% {
    clip-path: var(--move1);
    transform: translate(0px, -10px);
  }

  10% {
    clip-path: var(--move2);
    transform: translate(-10px, 10px);
  }

  20% {
    clip-path: var(--move3);
    transform: translate(10px, 0px);
  }

  30% {
    clip-path: var(--move4);
    transform: translate(-10px, 10px);
  }

  40% {
    clip-path: var(--move5);
    transform: translate(10px, -10px);
  }

  50% {
    clip-path: var(--move6);
    transform: translate(-10px, 10px);
  }

  60% {
    clip-path: var(--move1);
    transform: translate(10px, -10px);
  }

  70% {
    clip-path: var(--move3);
    transform: translate(-10px, 10px);
  }

  80% {
    clip-path: var(--move2);
    transform: translate(10px, -10px);
  }

  90% {
    clip-path: var(--move4);
    transform: translate(-10px, 10px);
  }

  100% {
    clip-path: var(--move1);
    transform: translate(0);
  }
}
