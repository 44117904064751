.video-wrapper {
  display: flex;
  justify-content: center; /* Center the container horizontally */
  margin: 0 auto;
  padding-top: 2%;
}

/* Adjust padding on smaller screens */
@media (max-width: 768px) {
  .video-wrapper {
    padding-top: 5%; /* Increase padding on mobile */
    width: 700px;
  }

  .video-wrapper {
    min-width: 400px;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  width: 100%; /* Ensure it takes full width of its parent */
  max-width: 100%;
  background: #000;
  margin: 0 auto;
  border: none;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
