.cta-button-container {
  margin-left: 80%;
}

.ui-btn-strat {
  --btn-default-bg: #141721;
  --btn-padding: 12px 24px;
  --btn-transition: 0.3s;
  --btn-letter-spacing: 0.25rem;
  --btn-animation-duration: 1.2s;
  --btn-shadow-color: rgba(0, 0, 0, 0.137);
  --btn-shadow: 0 2px 10px 0 var(--btn-shadow-color);
  --hover-btn-color: white;
  --default-btn-color: #fff;
  --font-size: 16px;
  /* 👆 this field should not be empty */
  --font-weight: 400;
  --font-family: "Montserrat";
  /* 👆 this field should not be empty */
}

/* button settings 👆 */

.ui-btn-strat {
  box-sizing: border-box;
  padding: var(--btn-padding);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--default-btn-color);
  font: var(--font-weight) var(--font-size) var(--font-family);
  background: var(--btn-default-bg);
  border: 1px solid black;
  border-radius: 10px;
  cursor: pointer;
  transition: var(--btn-transition);
  overflow: hidden;
  box-shadow: var(--btn-shadow);
}

.ui-btn-strat span {
  letter-spacing: var(--btn-letter-spacing);
  transition: var(--btn-transition);
  box-sizing: border-box;
  position: relative;
  background: inherit;
}

.ui-btn-strat span::before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  background: inherit;
}

.ui-btn-strat:hover,
.ui-btn-strat:focus {
  background: var(--btn-hover-bg);
}

.ui-btn-strat:hover span,
.ui-btn-strat:focus span {
  color: var(--hover-btn-color);
}

.ui-btn-strat:hover span::before,
.ui-btn-strat:focus span::before {
  animation: chitchat linear both var(--btn-animation-duration);
}

.ui-btn {
  --btn-default-bg: transparent;
  --btn-padding: 12px 24px;
  --btn-hover-bg: transparent;
  --btn-transition: 0.3s;
  --btn-letter-spacing: 0.25rem;
  --btn-animation-duration: 1.2s;
  --btn-shadow-color: rgba(0, 0, 0, 0.137);
  --btn-shadow: 0 2px 10px 0 var(--btn-shadow-color);
  --hover-btn-color: #4ea4a4;
  --default-btn-color: #fff;
  --font-size: 16px;
  /* 👆 this field should not be empty */
  --font-weight: 400;
  --font-family: Menlo, Roboto Mono, monospace;
  /* 👆 this field should not be empty */
}

/* button settings 👆 */

.ui-btn {
  box-sizing: border-box;
  padding: var(--btn-padding);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--default-btn-color);
  font: var(--font-weight) var(--font-size) var(--font-family);
  background: var(--btn-default-bg);
  border: 1px solid #04dfe2;
  border-radius: 10px;
  cursor: pointer;
  transition: var(--btn-transition);
  overflow: hidden;
  box-shadow: var(--btn-shadow);
}

.ui-btn span {
  letter-spacing: var(--btn-letter-spacing);
  transition: var(--btn-transition);
  box-sizing: border-box;
  position: relative;
  background: inherit;
}

.ui-btn span::before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  background: inherit;
}

.ui-btn:hover,
.ui-btn:focus {
  background: var(--btn-hover-bg);
}

.ui-btn:hover span,
.ui-btn:focus span {
  color: var(--hover-btn-color);
}

.ui-btn:hover span::before,
.ui-btn:focus span::before {
  animation: chitchat linear both var(--btn-animation-duration);
}

@keyframes chitchat {
  0% {
    content: "#";
  }

  5% {
    content: ".";
  }

  10% {
    content: "^{";
  }

  15% {
    content: "-!";
  }

  20% {
    content: "#$_";
  }

  25% {
    content: "№:0";
  }

  30% {
    content: "#{+.";}35%{content: "@}-?";
  }

  40% {
    content: "?{4@%";
  }

  45% {
    content: "=.,^!";
  }

  50% {
    content: "?2@%";
  }

  55% {
    content: "\;1}]";
  }

  60% {
    content: "?{%:%";
    right: 0;
  }

  65% {
    content: "|{f[4";
    right: 0;
  }

  70% {
    content: "{4%0%";
    right: 0;
  }

  75% {
    content: "'1_0<";
    right: 0;
  }

  80% {
    content: "{0%";
    right: 0;
  }

  85% {
    content: "]>'";
    right: 0;
  }

  90% {
    content: "4";
    right: 0;
  }

  95% {
    content: "2";
    right: 0;
  }

  100% {
    content: "";
    right: 0;
  }
}

/*bg*/
/*txt*/
/*success*/
.buttons {
  display: flex;
  justify-content: space-around;
  top: 20px;
  left: 20px;
}

.buttons button {
  width: 160px;
  height: 50px;
  background: linear-gradient(90deg, #4478d7, #166dc1);
  margin: 0.25rem;
  color: white;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.buttons button:before,
.buttons button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #4478d7, #166dc1);
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button:before {
  right: 0;
  top: 0;
  transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button:after {
  left: 0;
  bottom: 0;
}

.buttons button span {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.buttons button span:before,
.buttons button span:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 0;
  background: linear-gradient(90deg, #4478d7, #166dc1);
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button span:before {
  right: 0;
  top: 0;
  transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button span:after {
  left: 0;
  bottom: 0;
}

.buttons button p {
  padding: 0;
  margin: 0;
  transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
  position: absolute;
  width: 100%;
  height: 100%;
}

.buttons button p:before,
.buttons button p:after {
  position: absolute;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
  z-index: 1;
  left: 0;
}

.buttons button p:before {
  content: attr(data-title);
  top: 50%;
  transform: translateY(-50%);
}

.buttons button p:after {
  content: attr(data-text);
  top: 150%;
  color: white;
}

.buttons button:hover:before,
.buttons button:hover:after {
  width: 100%;
}

.buttons button:hover span {
  z-index: 1;
}

.buttons button:hover span:before,
.buttons button:hover span:after {
  height: 100%;
}

.buttons button:hover p:before {
  top: -50%;
  transform: rotate(5deg);
}

.buttons button:hover p:after {
  top: 50%;
  transform: translateY(-50%);
}

.buttons button.start {
  background-color: #44d8a4;
  box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.buttons button.start p:before {
  top: -50%;
  transform: rotate(5deg);
}

.buttons button.start p:after {
  color: white;
  transition: all 0s ease;
  content: attr(data-start);
  top: 50%;
  transform: translateY(-50%);
  animation: start 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes start {
  from {
    top: -50%;
  }
}

.buttons button.start:hover:before,
.buttons button.start:hover:after {
  display: none;
}

.buttons button.start:hover span {
  display: none;
}

.buttons button:active {
  outline: none;
  border: none;
}

.buttons button:focus {
  outline: 0;
}
