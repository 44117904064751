.strategy-info-block {
  display: flex;
  align-items: center;
  text-align: left;
  overflow: hidden;
  color: white;
  margin-bottom: 5%;
}

.strategy-info-block img {
  width: 40%; /* Adjust the width as needed */
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  border: 2px solid white;
}

.strategy-info-block__content {
  display: inline-block;
  margin-top: 56px;
  padding-left: 120px;
}

.strategy-info-block__title {
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 28px;
}
@media (max-width: 768px) {
  .strategy-info-block__title {
    margin-left: -5%;
  }
}

.strategy-info-block__description {
  justify-items: auto;
  justify-content: space-between;
  font-size: 1.2rem;
  padding-bottom: 56px;
}
@media (max-width: 768px) {
  .strategy-info-block__description {
    margin-left: -5%;
  }
}

/* Media query for screens with max width of 768px (typical mobile devices) */
@media (max-width: 1200px) {
  .strategy-info-block {
    flex-direction: column;
    justify-content: center;
  }

  .strategy-info-block img {
    width: auto;
    max-width: 60vw;
    border: 2px solid #ccffff;
  }

  .strategy-info-block__content {
    display: inline-block;
    margin-top: 56px;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media (max-width: 768px) {
  .strategy-info-block img {
    max-width: 95vw;
  }

  .strategy-info-block__content {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.strategy-btn-container {
  margin-top: -40px; /* Adjust spacing as needed */
  text-align: left; /* Ensures the button aligns to the left edge of the content */
}

.hero-btn {
  padding: 12px 24px;
  min-width: 150px;
  min-height: 50px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(90deg, #4478d7, #166dc1);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.2;
  white-space: normal;
}

.hero-btn:hover {
  background: linear-gradient(90deg, #3564b7, #134a91);
  transform: scale(1.03);
}

.hero-btn p {
  margin: 0;
  padding: 0 8px;
  white-space: normal;
}
