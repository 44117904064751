.navbar {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 0.3s ease-in-out;
  z-index: 1000;
}

.navbar.scrolled {
  background: rgba(0, 0, 0, 0.9);
}

.nav_logo img {
  height: 40px;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding-bottom: 5px;
  transition: 0.3s;
}

.nav-links li.active a {
  border-bottom: 3px solid #7253a3;
}

.burger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .burger-menu {
    display: flex;
    position: relative;
    z-index: 1001; /* Ensure it's clickable */
  }

  .nav-links {
    position: absolute;
    top: 60px;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    display: none;
  }

  .nav-links.open {
    transform: translateY(0);
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .bar.open:nth-child(1) {
    transform: rotate(45deg) translateY(8px);
  }

  .bar.open:nth-child(2) {
    opacity: 0;
  }

  .bar.open:nth-child(3) {
    transform: rotate(-45deg) translateY(-8px);
  }
}
